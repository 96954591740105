// branding configuration
$publication: 'derbytelegraph';
$logo-dimensions: 600, 160;
$primary-color: #1E1A54;
$header-additional: #4D4D4F;
$brand-hue: 244;
$brand-saturation: 53%;

$brand-color: hsl($brand-hue, $brand-saturation, 22%);
$highlight: hsl($brand-hue, $brand-saturation, 52%);
$hint: hsl($brand-hue, $brand-saturation,  97%);
$lowlight: hsl($brand-hue, $brand-saturation,  7%);
// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }
}
